import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import '../../vendor/wire-elements/pro/resources/js/overlay-component.js'
import haversine from 'haversine-distance';
import * as Sentry from "@sentry/browser";
import tippy from 'tippy.js';

window.haversine = haversine;
window.tippy = tippy;

Sentry.init({
    dsn: window.sentry_dsn,
    release: window.sentry_release,
    environment: window.sentry_environment,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: window.sentry_traces_sample_rate,
});

Livewire.start();
